<template>
  <pre class="line-numbers" :class="`language-${language}`">
    <code v-html="highlightedCode" style="margin-left: -40px;"></code>
  </pre>
</template>

<script setup>
import { ref, watch } from "vue";
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // 引入主题样式
import "prismjs/components/prism-c"; // 引入 C++ 语法高亮
import "prismjs/components/prism-python"; // 引入 Python 语法高亮
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    required: true,
  },
});

const highlightedCode = ref("");

const updateHighlightedCode = () => {
  if (Prism.languages[props.language]) {
    highlightedCode.value = Prism.highlight(
      props.code,
      Prism.languages[props.language],
      props.language
    );
        setTimeout(() => Prism.highlightAll(), 0);
  } else {
    console.warn(`Prism: Language "${props.language}" is not supported.`);
    highlightedCode.value = props.code; // 如果语言不支持，则直接显示原始代码
  }
};

// 初次渲染
updateHighlightedCode();

// 监听 props 变化
watch(() => [props.code, props.language], updateHighlightedCode);
</script>
<style lang="less" scoped>
</style>